$(function() {
	
    $("#nav-toggle").click(function(){
		$("#mobil-menu").toggleClass("open");
		$(this).toggleClass("open");
	});
    
    $("#mobil-menu .closer").click(function(){
		$("#mobil-menu").removeClass("open");
		$("#nav-toggle").removeClass("open");
    });

    $(".main-menu li.dropdown > a").click(function (e) {
        e.preventDefault();
        $(this).parent().find("ul.dropdown-menu:first").toggleClass("open");
    });
    
    $("#mobil-menu .dropdown-toggle").click(function(){
		
        $(this).siblings("ul.dropdown:first").slideToggle();
	});
	
	$(".scrolldown-bottom").click(function() {
		$('html,body').animate({scrollTop: $("#about").offset().top - 50},'slow');
    });
	
});